import { React, useEffect, useState } from 'react';
import { startBatch } from 'services/cuttingService';
import { Table } from 'react-bootstrap';
import StationSpinner from 'components/station/common/Spinner';

export default function SawCutting(props) {
  let barcode = props.barcode;
  let startStage = props.startStage;
  let isWorking = props.isWorking;

  const [cuttingItems, setCuttingItems] = useState([]);
  const [batchNo, setBatchNo] = useState(0);
  const [subBatchNo, setSubBatchNo] = useState(0);
  const [status, setStatus] = useState('N/A');

  const [isLoading, setIsLoading] = useState(false);

  let cuttingItemsRows = [];

  useEffect(() => {
    if (isWorking) {
      // let lastBarcode = sessionStorage.getItem('last-scanned-barcode');

      // if (lastBarcode != barcode) {
      //   console.log('need complete last');
      // }

      if (barcode > 0) {
        setIsLoading(true);
        startBatch(barcode, startStage).then(res => {
          console.log(res);

          if (res?.success && res?.batchData) {
            setCuttingItems(res?.batchData);
            setBatchNo(res?.batchNo);
            setSubBatchNo(res?.subBatchNo);
            setStatus('Cutting');
            setIsLoading(false);
            props.onStartBatch(res);
          }
        });
      } else {
        setCuttingItems([]);
        setBatchNo(0);
        setSubBatchNo(0);
      }
    }
  }, [barcode]);

  if (cuttingItems.length > 0) {
    cuttingItems.forEach(function (item, index) {
      cuttingItemsRows.push(
        <tr key={'row' + index}>
          <td>{item.slotNumber}</td>
          <td>{item.jobNo}</td>
          <td>{item.winNo}</td>
          <td>{item.sashNo}</td>
          <td>{`${item.width} x ${item.length}`} </td>
          <td>{item.groupDesc}</td>
          <td>{item.pCode}</td>
        </tr>
      );
    });
  } else {
    cuttingItemsRows.push(
      <tr key={'row0'}>
        <td colSpan={7}>No records found</td>
      </tr>
    );
  }

  return !isLoading ? (
    <>
      <h5>
        {batchNo} / {subBatchNo}
      </h5>
      <h5>Status: {status}</h5>
      <br></br>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Slot No</th>
            <th>Job No</th>
            <th>Win No</th>
            <th>Sash No</th>
            <th>Frame Size</th>
            <th>Group</th>
            <th>Profile</th>
          </tr>
        </thead>
        <tbody>{cuttingItemsRows}</tbody>
      </Table>
    </>
  ) : (
    <StationSpinner></StationSpinner>
  );
}
