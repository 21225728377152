import axios from 'axios';

const getFaultListByProductType = async productType => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/QC/fault-list?productType=${productType}`
    );

    return {
      success: true,
      faultList: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getRejectLog = async () => {
  try {
    const res = await axios(`${process.env.REACT_APP_API_HOST}/QC/reject-log`);

    return {
      success: true,
      rejectLog: res.data.rejectLog,
      jobNo: res.data.jobNo,
      winNo: res.data.winNo
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getRejectLogs = async (userId, pageLength) => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/QC/reject-logs?userId=${userId}&pageLength=${pageLength}`
    );

    return {
      success: true,
      rejectLogs: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addRejectLog = async logItem => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/QC`,
    method: 'POST',
    data: logItem
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addAlertLog = async alertItem => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/QC/reject-alert`,
    method: 'POST',
    data: alertItem
  });

  try {
    return {
      success: res.data.alertLogInserted
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getFaultAreas = async () => {
  try {
    const res = await axios(`${process.env.REACT_APP_API_HOST}/QC/fault-areas`);

    return {
      success: true,
      areas: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getDecisions = async () => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/QC/fault-decisions`
    );

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addDecision = async decision => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/QC/fault-decision`,
    method: 'POST',
    data: { decision }
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getAncillIntoPack = async data => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/ancill-pack/start`,
      method: 'POST',
      data: data
    });

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getWeledCillByBarcode = async barcode => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/QC/welded-cill?barcode=${barcode}`
    );

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addAncillChecking = async data => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/QC/ancill-checking`,
    method: 'POST',
    data: data
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addWeldedCillChecking = async weldedCillCheckingRequest => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/QC/welded-cill-checking`,
    method: 'POST',
    data: weldedCillCheckingRequest
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const startWeledCill = async data => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/welded-cill/start`,
      method: 'POST',
      data: data
    });

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const startGlass = async data => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/glass/start`,
      method: 'POST',
      data: data
    });

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const startHardware = async data => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/hardware/start`,
      method: 'POST',
      data: data
    });

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getGlassByBarcode = async barcode => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/QC/glass?barcode=${barcode}`
    );

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addGlassChecking = async glassCheckingRequests => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/QC/glass-checking`,
    method: 'POST',
    data: glassCheckingRequests
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const startStillage = async data => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/stillage/start`,
      method: 'POST',
      data: data
    });

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getStillageByBarcode = async barcode => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/QC/stillage?barcode=${barcode}`
    );

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addStillageChecking = async stillageCheckingRequest => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/QC/stillage-checking`,
    method: 'POST',
    data: stillageCheckingRequest
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addHardwaresChecking = async hardwareCheckings => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/QC/hardware-checking`,
    method: 'POST',
    data: hardwareCheckings
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export {
  getFaultListByProductType,
  getRejectLog,
  getRejectLogs,
  addRejectLog,
  addAlertLog,
  getFaultAreas,
  getDecisions,
  addDecision,
  getAncillIntoPack,
  addAncillChecking,
  getWeledCillByBarcode,
  addWeldedCillChecking,
  startWeledCill,
  startGlass,
  startStillage,
  startHardware,
  getGlassByBarcode,
  addGlassChecking,
  getStillageByBarcode,
  addStillageChecking,
  addHardwaresChecking
};
