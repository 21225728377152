import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFrameImageSizeSetting } from 'services/widgetSettingService';

const initialState = {
  frameImageSize: {},
  partImageSize: {},
  partV5ImageSize: {}
};

export const getFrameImageSize = createAsyncThunk(
  'widget/getFrameImageSize',
  async panelSetupId => {
    try {
      const res = await getFrameImageSizeSetting(panelSetupId);
      return res.size;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPartImageSize = createAsyncThunk(
  'widget/getPartImageSize',
  async panelSetupId => {
    try {
      const res = await getFrameImageSizeSetting(panelSetupId);
      return res.size;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPartV5ImageSize = createAsyncThunk(
  'widget/getPartV5ImageSize',
  async panelSetupId => {
    try {
      const res = await getFrameImageSizeSetting(panelSetupId);
      return res.size;
    } catch (error) {
      console.log(error);
    }
  }
);

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {},
  extraReducers: {
    [getFrameImageSize.fulfilled]: (state, action) => {
      state.frameImageSize = action.payload;
    },
    [getPartImageSize.fulfilled]: (state, action) => {
      state.partImageSize = action.payload;
    },
    [getPartV5ImageSize.fulfilled]: (state, action) => {
      state.partV5ImageSize = action.payload;
    }
  }
});

export default widgetSlice.reducer;
