import axios from 'axios';

const getSashInfo = async barcode => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/sash-location?barcode=${barcode}`,
    method: 'GET'
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const clearSashLocation = async data => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/sash-location`,
    method: 'PUT',
    data: data
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export { getSashInfo, clearSashLocation };
