import axios from 'axios';

const startBatch = async (barcode, startStage) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/batch/start?barcode=${barcode}&startStage=${startStage}`,
    method: 'POST'
  });

  try {
    return {
      success: res.data.success,
      batchNo: res.data.batchNo,
      subBatchNo: res.data.subBatchNo,
      batchData: res.data.batchData,
      isSash: res.data.isSash
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const completeBatch = async (request, completedStage) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/batch/complete?completedStage=${completedStage}`,
    method: 'POST',
    data: request
  });

  try {
    return {
      completed: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getCuttingItems = async currentDate => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/cuttings?date=${currentDate}`,
    method: 'GET'
  });

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getCompositeDoorJobs = async currentDate => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/composite-door-jobs?date=${currentDate}`,
    method: 'GET'
  });

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getRemakeJobs = async currentDate => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/remake-jobs?date=${currentDate}`,
    method: 'GET'
  });

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getCuttingitemDetails = async headerId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/cutting-details?headerId=${headerId}`,
    method: 'GET'
  });

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getCompositeDoorJobDetails = async headerId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/composite-door-job-details?headerId=${headerId}`,
    method: 'GET'
  });

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getPacksByHeaderId = async headerId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/ancil-packs?headerId=${headerId}`,
    method: 'GET'
  });

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deletePack = async boxId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/ancil-pack/${boxId}`,
    method: 'DELETE'
  });

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getItemsInPack = async boxId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/ancil-pack?boxId=${boxId}`,
    method: 'GET'
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteItemInPack = async item => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/ancil-pack-item`,
    method: 'DELETE',
    data: { partsItemId: item.partsItemId, itemNo: item.itemNo }
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getItemLinkPack = async headerId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/item-link-to-cut?headerId=${headerId}`,
    method: 'GET'
  });

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const moveToProductionStage = async barcode => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/QC/move-to-production-stage`,
    method: 'PUT',
    data: { barcode }
  });

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export {
  startBatch,
  completeBatch,
  getCuttingItems,
  getCuttingitemDetails,
  getPacksByHeaderId,
  deletePack,
  deleteItemInPack,
  getItemsInPack,
  getCompositeDoorJobs,
  getRemakeJobs,
  getCompositeDoorJobDetails,
  getItemLinkPack,
  moveToProductionStage
};
