import axios from 'axios';

const getPartItems = async (barcode, group) => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/Panel/part-items?barcode=${barcode}&filterGroup=${group}`
    );

    return {
      message: 'Successful response',
      success: res.data.success,
      partItems: res.data.partItems
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getPartItemsV2 = async (barcode, group, includeImages = false) => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/Panel/part-items-v2?barcode=${barcode}&filterGroup=${group}&includeImages=${includeImages}`
    );

    return {
      message: 'Successful response',
      success: res.data.success,
      partItems: res.data.partItems
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getPartItemsV3 = async (barcode, group) => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/Panel/part-items-v3?barcode=${barcode}&filterGroup=${group}`
    );

    return {
      message: 'Successful response',
      success: res.data.success,
      partItems: res.data.partItems
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getPartItemsV4 = async (barcode, group) => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/Panel/part-items-v4?barcode=${barcode}&filterGroup=${group}`
    );

    return {
      message: 'Successful response',
      success: res.data.success,
      partItems: res.data.partItems
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getGlassPartItems = async (barcode, group) => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/Panel/glass-part-items?barcode=${barcode}&filterGroup=${group}`
    );

    return {
      message: 'Successful response',
      success: res.data.success,
      glassPartItems: res.data.glassPartItems
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getStraightItem = async barcode => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/Panel/straight-warning?barcode=${barcode}`
    );

    return {
      data: res.data,
      success: res.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addPartsTranslate = async partsTranslate => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_HOST}/Panel/ss-description`,
      method: 'POST',
      data: partsTranslate
    });

    return {
      data: res.data,
      success: res.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const editPartsTranslate = async (id, partsTranslate) => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_HOST}/Panel/ss-description?id=${id}`,
      method: 'PUT',
      data: partsTranslate
    });

    return {
      data: res.data,
      success: res.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getPartsTranslate = async search => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_HOST}/Panel/ss-descriptions?search=${search}`,
      method: 'GET'
    });

    return {
      data: res.data,
      success: res.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deletePartsTranslate = async id => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_HOST}/Panel/ss-description?id=${id}`,
      method: 'DELETE'
    });

    return {
      data: res.data,
      success: res.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export {
  getPartItems,
  getGlassPartItems,
  getPartItemsV2,
  getPartItemsV3,
  getStraightItem,
  getPartItemsV4,
  addPartsTranslate,
  editPartsTranslate,
  getPartsTranslate,
  deletePartsTranslate
};
