import { React, useEffect, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { startScanFrame } from 'services/frameService';
// import defaultFrameImg from '../../assets/img/Emplas/stillage.png';
import FrameImage from 'components/station/FrameImage';
import StationSpinner from 'components/station/common/Spinner';
import { swalToast, swalToastError } from 'helpers/component-utils';
import '../../assets/scss/user.scss';
import { CATEGORIES, warningGroupDescs } from 'helpers/utils';

var dayjs = require('dayjs');

export default function FrameDetailWidget(props) {
  let barcode = props.barcode;
  let startStage = props.startStage;
  let isWorking = props.isWorking;
  let userId = props.userId;
  let stationId = props.stationId;
  let showFrameWeight = props.showFrameWeight;

  const [frameInfo, setFrameInfo] = useState({
    jobNo: '',
    mainName: '',
    requiredDate: '',
    winNo: '',
    groupDesc: '',
    finishDesc: '',
    finish2Desc: '',
    priorityType: '',
    instructions: '',
    totalWeight: 0
  });
  const [isLoading, setIsLoading] = useState(false);
  const [frameWeightDisplayed, setFrameWeightDisplayed] =
    useState(showFrameWeight);

  useEffect(() => {
    if (isWorking) {
      if (barcode > 0 || barcode.toString().toLowerCase().startsWith('tg')) {
        props.onScanStarted();
        var payload = { barcode: barcode, stage: startStage, userId: userId };
        setIsLoading(true);
        console.log('starting...');
        startScanFrame(payload)
          .then(res => {
            if (warningGroupDescs.includes(res?.frameDetail?.groupDesc)) {
              props.setIsShowPatioWarning(true);
              props.updateQueue('isShowParioWarning');
            }

            if (res?.isForwardManu && stationId == 17) {
              props.setShowModalGFMA(true);
              props.updateQueue('showModalGFMA');
            } else {
              props.setShowModalGFMA(false);
            }

            if (res?.isNewAndCritical) {
              props.setShowNewCriticalAlert(true);
              props.updateQueue('showNewCriticalAlert');
            } else {
              props.setShowNewCriticalAlert(false);
            }

            if (res?.success && res?.frameDetail) {
              setFrameInfo({ ...res.frameDetail });
              // props.onStartScanBarcode(res.frameDetail);
              props.onScanCompleted(res?.userTrackingId);
              props.setProductComplted(CATEGORIES.FRAME);
              console.log('started...');
            }
            setIsLoading(false);
          })
          .catch(err => {
            console.log(err);
            swalToastError('Invalid Barcode. Please Scan Again');
            setIsLoading(false);
            props.onScanCompleted(0);
          });
      } else {
        setFrameInfo({
          jobNo: '',
          mainName: '',
          requiredDate: '',
          winNo: '',
          groupDesc: '',
          finishDesc: '',
          finish2Desc: '',
          priorityType: '',
          instructions: '',
          totalWeight: 0
        });
      }
    }
  }, [props.barcode]);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div style={{ padding: 0, margin: 0 }}>
      <Row className="thinnerRow">
        <Col sm={5}>
          <b className="widget-label">Job No:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">{frameInfo.jobNo}</p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          {' '}
          <b className="widget-label">Customer:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">
            {frameInfo.mainName}{' '}
            <Badge bg="danger">{frameInfo.priorityType}</Badge>
          </p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          {' '}
          <b className="widget-label">Date Required:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">
            {frameInfo.requiredDate
              ? dayjs(frameInfo.requiredDate).format('DD/MM/YYYY')
              : ''}
          </p>
        </Col>
      </Row>
      <Row className="">
        <Col sm={5}>
          {' '}
          <b className="widget-label">Win No:</b>
        </Col>
        <Col sm={2}>
          <p className="widget-text">{frameInfo.winNo}</p>
        </Col>
        {showFrameWeight ? (
          <>
            <Col sm={2}>
              <b className="widget-label" style={{ textAlign: 'center' }}>
                Total Weight:
              </b>
            </Col>
            <Col
              sm={2}
              style={{
                backgroundColor: 'red',
                textAlign: 'center',
                minHeight: '40px'
              }}
            >
              <b
                className="widget-label"
                style={{
                  textAlign: 'center',
                  top: '14px',
                  position: 'relative',
                  color: 'black'
                }}
              >
                {frameInfo.totalWeight} Kg
              </b>
            </Col>
          </>
        ) : (
          ''
        )}
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          {' '}
          <b className="widget-label">Group:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">{frameInfo.groupDesc}</p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          {' '}
          <b className="widget-label">Finish 1:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">{frameInfo.finishDesc}</p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          {' '}
          <b className="widget-label">Finish 2:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">{frameInfo.finish2Desc}</p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          <b className="widget-label" style={{ color: '#d9534f' }}>
            Special Instructions:
          </b>
        </Col>
        <Col sm={7}>
          <p className="widget-label" style={{ color: '#d9534f' }}>
            {frameInfo.instructions}
          </p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={3}></Col>
        <Col sm={6}>
          <FrameImage
            jobNo={frameInfo.jobNo}
            winNo={frameInfo.winNo}
          ></FrameImage>
        </Col>
      </Row>
    </div>
  );
}
