import StationSpinner from 'components/station/common/Spinner';
import dayjs from 'dayjs';
import { swalToast, swalToastError } from 'helpers/component-utils';
import {
  isNewOrCriticalCustomer,
  isCriticalCustomer,
  isNewCustomer
} from 'helpers/utils';
import { React, useEffect, useState } from 'react';
import { Badge, Button, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {
  getCompositeDoorJobs,
  getCuttingItems,
  getRemakeJobs
} from 'services/cuttingService';

export default function CuttingListWidget(props) {
  const [date, setDate] = useState(new Date());
  const [isShowNotCut, setIsShowNotCut] = useState(false);
  const [cuttingsNotGroup, setCuttingsNotGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryRouteGrouped, setDeliveryRouteGrouped] = useState([]);
  const [titleJobList, setTitleJobList] = useState('default');
  const [reloadData, setReloadData] = useState();
  const [extendRoute, setExtendRoute] = useState([]);
  const [extendMainName, setExtendMainName] = useState([]);

  const getCurrentDate = () => {
    const currentDate = dayjs(date).format('YYYY-MM-DD');
    return currentDate;
  };

  const loadDefaultJobs = currentDate => {
    setIsLoading(true);

    getCuttingItems(currentDate)
      .then(res => {
        setIsLoading(false);
        if (res.data && res.data.length > 0) {
          const data = res.data.filter(item => item.total > 0);

          const dataSortedByRefixJob = data.sort((a, b) => {
            if (a.jobNo.length > 8) {
              return -1;
            }
          });

          const dataSortedByCustomerType = dataSortedByRefixJob.sort((a, b) => {
            if (isNewOrCriticalCustomer(a.priorityType)) {
              return -1;
            }
          });

          if (isShowNotCut) {
            const result = dataSortedByCustomerType.filter(
              c => c.cut < c.total
            );
            const groupByDeliveryRoute = Object.groupBy(
              result,
              ({ deliveryRoute }) => deliveryRoute
            );
            setDeliveryRouteGrouped(groupByDeliveryRoute);
          } else {
            const groupByDeliveryRoute = Object.groupBy(
              dataSortedByCustomerType,
              ({ deliveryRoute }) => deliveryRoute
            );
            setDeliveryRouteGrouped(groupByDeliveryRoute);
          }
          setCuttingsNotGroup(dataSortedByCustomerType);
        } else {
          setDeliveryRouteGrouped([]);
          setCuttingsNotGroup([]);
        }
      })
      .catch(error => {
        swalToast.fire({
          icon: 'error',
          title: `Get Cutting Details Failed`,
          background: '#d9534f',
          iconColor: '#fff',
          color: '#fff'
        });
      });
  };

  const loadCompositeDoorJobs = currentDate => {
    setIsLoading(true);

    getCompositeDoorJobs(currentDate)
      .then(res => {
        setIsLoading(false);
        if (res.data && res.data.length > 0) {
          const data = res.data.filter(item => item.total > 0);

          const dataSortedByRefixJob = data.sort((a, b) => {
            if (a.jobNo.length > 8) {
              return -1;
            }
          });

          const dataSortedByCustomerType = dataSortedByRefixJob.sort((a, b) => {
            if (isNewOrCriticalCustomer(a.priorityType)) {
              return -1;
            }
          });

          const groupByDeliveryRoute = Object.groupBy(
            dataSortedByCustomerType,
            ({ deliveryRoute }) => deliveryRoute
          );
          setDeliveryRouteGrouped(groupByDeliveryRoute);
          setCuttingsNotGroup(dataSortedByCustomerType);
        } else {
          setDeliveryRouteGrouped([]);
          setCuttingsNotGroup([]);
        }
      })
      .catch(error => {
        swalToastError(`Get Cutting Details Failed`);
      });
  };

  const loadRemakeJobs = currentDate => {
    setIsLoading(true);

    getRemakeJobs(currentDate)
      .then(res => {
        setIsLoading(false);
        if (res.data && res.data.length > 0) {
          const data = res.data.filter(item => item.total > 0);

          const dataSortedByRefixJob = data.sort((a, b) => {
            if (a.jobNo.length > 8) {
              return -1;
            }
          });

          const dataSortedByCustomerType = dataSortedByRefixJob.sort((a, b) => {
            if (isNewOrCriticalCustomer(a.priorityType)) {
              return -1;
            }
          });

          const groupByDeliveryRoute = Object.groupBy(
            dataSortedByCustomerType,
            ({ deliveryRoute }) => deliveryRoute
          );
          setDeliveryRouteGrouped(groupByDeliveryRoute);
          setCuttingsNotGroup(dataSortedByCustomerType);
        } else {
          setDeliveryRouteGrouped([]);
          setCuttingsNotGroup([]);
        }
      })
      .catch(error => {
        swalToastError(`Get Cutting Details Failed`);
      });
  };

  useEffect(() => {
    const currentDate = getCurrentDate();
    setTitleJobList('default');

    loadDefaultJobs(currentDate);
  }, [isShowNotCut]);

  useEffect(() => {
    clearInterval(reloadData);
    autoRefreshJobs();
    return () => {
      clearInterval(reloadData);
    };
  }, [titleJobList, date]);

  const autoRefreshJobs = () => {
    const currentDate = getCurrentDate();
    if (titleJobList === 'remake') {
      loadRemakeJobs(currentDate);
      setReloadData(
        setInterval(() => {
          loadRemakeJobs(currentDate);
        }, 5 * 60 * 1000)
      );
    } else if (titleJobList === 'composite') {
      loadCompositeDoorJobs(currentDate);
      setReloadData(
        setInterval(() => {
          loadCompositeDoorJobs(currentDate);
        }, 5 * 60 * 1000)
      );
    } else {
      loadDefaultJobs(currentDate);
      setReloadData(
        setInterval(() => {
          loadDefaultJobs(currentDate);
        }, 5 * 60 * 1000)
      );
    }
  };

  const handleChangeViewCut = () => {
    setIsShowNotCut(!isShowNotCut);
  };

  const handleOpenCuttingDetail = item => {
    props.setCurrentCuttingDetail(item);
    props.setIsShowCuttingDetail(true);
    props.setTitleCuttingList(titleJobList);
  };

  const handleCollapseMainName = mainName => {
    const element = document.getElementById(`collapseMainName${mainName}`);
    if (element) {
      if (element.classList.value.includes('show')) {
        element.classList.remove('show');
        setExtendMainName(extendMainName.filter(item => item != mainName));
      } else {
        element.classList.add('show');
        setExtendMainName([...extendMainName, mainName]);
      }
      console.log(extendMainName);
    }
  };

  const handleCollapseDeliveryRoute = (route, idx) => {
    const element = document.getElementById(`collapseDeliveryRoute${idx}`);
    if (element) {
      if (element.classList.value.includes('show')) {
        element.classList.remove('show');
        setExtendRoute(extendRoute.filter(item => item != route));
      } else {
        element.classList.add('show');
        setExtendRoute([...extendRoute, route]);
      }
    }
  };

  const generateMainNameGroupedSorted = jobs => {
    if (jobs.length > 0) {
      return jobs.map(
        (record, ind) =>
          record.total > 0 && (
            <div
              key={ind}
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                alignItems: 'center',
                padding: '5px 20px',
                backgroundColor: record.cut == record.total ? '#a7f2ca' : ''
              }}
            >
              <div>{record.jobNo}</div>
              <div>{record.cut}</div>
              <div>{record.total}</div>
              <div>
                <Button
                  style={{
                    backgroundColor: record.cut == record.total ? '#a7f2ca' : ''
                  }}
                  variant="falcon-default"
                  onClick={() => handleOpenCuttingDetail(record)}
                >
                  Detail
                </Button>
              </div>
            </div>
          )
      );
    }
  };

  const newOrCriticalCustomer = priorityType => {
    if (isCriticalCustomer(priorityType)) {
      return (
        <Badge pill bg="warning" text="dark" className="me-2">
          {priorityType}
        </Badge>
      );
    } else if (isNewCustomer(priorityType)) {
      return (
        <Badge pill bg="info" className="me-2">
          {priorityType}
        </Badge>
      );
    }
  };

  const isAllJobsAsCut = jobs => {
    const jobsCut = jobs.filter(j => j.cut === j.total);
    if (jobsCut.length === jobs.length) {
      return true;
    } else {
      return false;
    }
  };

  const isExtendedMainName = mainName => {
    return extendMainName.includes(mainName);
  };

  const isExtendedDeliveryRoute = route => {
    return extendRoute.includes(route);
  };

  const generateMainNameGrouped = deliveryRoute => {
    const groupedByMainName = Object.groupBy(
      deliveryRoute,
      ({ mainName }) => mainName
    );
    if (groupedByMainName && Object.keys(groupedByMainName).length > 0) {
      return Object.keys(groupedByMainName).map((mainName, index) => (
        <div key={index}>
          <div
            className="fst-italic fw-bold d-flex align-items-center gap-2"
            role="button"
            onClick={() => handleCollapseMainName(mainName)}
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              padding: '10px 10px',
              backgroundColor: isAllJobsAsCut(groupedByMainName[mainName])
                ? '#a7f2ca'
                : ''
            }}
          >
            <div>{mainName}</div>
            <div>
              {newOrCriticalCustomer(
                groupedByMainName[mainName][0].priorityType
              )}
            </div>
          </div>
          <div
            className={`collapse multi-collapse ${
              isExtendedMainName(mainName) ? 'show' : ''
            }`}
            id={'collapseMainName' + mainName}
            style={{
              backgroundColor: isAllJobsAsCut(groupedByMainName[mainName])
                ? '#a7f2ca'
                : ''
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                marginBottom: '10px',
                padding: '0 20px'
              }}
            >
              <div style={{ fontWeight: 600 }}>Job No.</div>
              <div style={{ fontWeight: 600 }}>Cut</div>
              <div style={{ fontWeight: 600 }}>Total</div>
              <div style={{ fontWeight: 600 }}></div>
            </div>

            {groupedByMainName[mainName].length > 0 &&
              generateMainNameGroupedSorted(groupedByMainName[mainName])}
          </div>
        </div>
      ));
    }
  };

  const handleChangeCompositeDoorJob = () => {
    const currentDate = getCurrentDate();
    setTitleJobList('composite');
    loadCompositeDoorJobs(currentDate);
  };

  const handleChangeRemakeJob = () => {
    const currentDate = getCurrentDate();
    setTitleJobList('remake');
    loadRemakeJobs(currentDate);
  };

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div className="overflow-hidden">
      <div className="d-flex justify-content-between align-items-center px-2 my-2">
        <DatePicker
          selected={date}
          onChange={date => setDate(date)}
          dateFormat="dd/MM/yyyy"
          className="form-control"
          placeholderText="Select Date"
        />
        <div>
          <Button
            variant="falcon-default"
            onClick={handleChangeCompositeDoorJob}
          >
            {'Composite Door Jobs'}
          </Button>
          <Button variant="falcon-default" onClick={handleChangeRemakeJob}>
            {'Remake Jobs'}
          </Button>
          <Button variant="falcon-default" onClick={handleChangeViewCut}>
            {isShowNotCut ? 'View All Cut Ancills' : 'View Not Cut Ancills'}
          </Button>
        </div>
      </div>
      <Row>
        {Object.keys(deliveryRouteGrouped).length > 0 ? (
          Object.keys(deliveryRouteGrouped).map((item, idx) => (
            <div key={idx}>
              <div
                className="fst-italic fw-bold"
                role="button"
                onClick={() => handleCollapseDeliveryRoute(item, idx)}
                style={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  backgroundColor: isAllJobsAsCut(deliveryRouteGrouped[item])
                    ? '#a7f2ca'
                    : ''
                }}
              >
                {item}
              </div>
              <div
                className={`collapse multi-collapse ${
                  isExtendedDeliveryRoute(item) ? 'show' : ''
                }`}
                id={'collapseDeliveryRoute' + idx}
              >
                {generateMainNameGrouped(deliveryRouteGrouped[item])}
              </div>
            </div>
          ))
        ) : (
          <div>
            <div className="text-center">No Record Found</div>
          </div>
        )}
      </Row>
    </div>
  );
}
