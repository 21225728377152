import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import { React, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  Row
} from 'react-bootstrap';
import { isMobile, isTablet } from 'react-device-detect';
import {
  addFaultList,
  getFaultDescriptions,
  getFaultGroups,
  getFrameProductType,
  getProductTypes,
  getProducts
} from 'services/frameService';
import {
  addDecision,
  addRejectLog,
  getDecisions,
  getFaultAreas,
  getFaultListByProductType
} from 'services/qcService';
import '../../assets/scss/user.scss';
import IconButton from 'components/common/IconButton';

export default function RejectWidget({
  barcode,
  userId,
  userName,
  permissionId
}) {
  const defaultFault = {
    product: '',
    productType: '',
    faultGroup: '',
    faultDesc: '',
    detail: '',
    comment: '',
    areaOccured: '',
    alertType: ''
  };

  const [isOpenQCModal, setIsOpenQCModal] = useState(false);
  const [productType, setProductType] = useState('');
  const [headerId, setHeaderId] = useState(0);
  const [frameId, setFrameId] = useState(0);
  const [faultList, setFaultList] = useState([]);

  const [products, setProducts] = useState([]);
  const [faultGroups, setFaultGroups] = useState([]);
  const [faultDescs, setFaultDescs] = useState([]);
  const [details, setDetails] = useState([]);
  const [comments, setComments] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedDesc, setSelectedDesc] = useState('');
  const [selectedDetail, setSelectedDetail] = useState('');
  const [selectedComment, setSelectedComment] = useState('');
  const [selectedDecision, setSelectedDecision] = useState('');
  const [selectedArea, setSelectedArea] = useState('');

  const [showGroups, setShowGroups] = useState(false);
  const [showDescs, setShowDescs] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showDecision, setShowDecision] = useState(false);
  const [isAddingNewFault, setIsAddingNewFault] = useState(false);
  const [newFault, setNewFault] = useState(defaultFault);
  const [productTypes, setProductTypes] = useState([]);
  const [productsAdding, setProductsAdding] = useState([]);
  const [areas, setAreas] = useState([]);
  const [decisions, setDecisions] = useState([]);
  const [decision, setDecision] = useState('');
  const [isTypingFaultGroup, setIsTypingFaultGroup] = useState(false);
  const [isTypingFaultDesc, setIsTypingFaultDesc] = useState(false);
  const [faultGroupsDropDown, setFaultGroupsDropDown] = useState([]);
  const [faultDescsDropDown, setFaultDescsDropDown] = useState([]);

  let faultProductOptions = [];
  let faultGroupOptions = [];
  let faultDescOptions = [];
  let faultDetailOptions = [];
  let faultCommentOptions = [];

  useEffect(() => {
    if (barcode) {
      getFrameProductType(barcode)
        .then(res => {
          if (res?.success) {
            setProductType(res.productType);
            setHeaderId(res.headerId);
            setFrameId(res.frameId);
          }
        })
        .catch(error => {
          swalToastError('Get Frame Product Type Failed!');
        });
    }
  }, [barcode]);

  useEffect(() => {
    getProductTypes()
      .then(res => {
        if (res && res.data) {
          setProductTypes(res.data);
        }
      })
      .catch(error => {
        console.log(error);
        swalToastError('Get Product Type Failed!');
      });

    getProducts()
      .then(res => {
        if (res && res.data) {
          setProductsAdding(res.data);
        }
      })
      .catch(error => {
        console.log(error);
        swalToastError('Get Products Adding Failed!');
      });

    getFaultAreas()
      .then(res => {
        setAreas(res.areas);
      })
      .catch(error => {
        console.log(error);
        swalToastError('Get Fault Areas Failed');
      });

    getFaultDescriptions()
      .then(res => {
        if (res && res.data) {
          setFaultDescsDropDown(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Fault Descriptions Failed');
      });

    getFaultGroups()
      .then(res => {
        if (res && res.data) {
          setFaultGroupsDropDown(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Fault Group Failed');
      });
  }, []);

  useEffect(() => {
    if (showDecision) {
      getDecisions()
        .then(res => {
          if (res && res.data) {
            setDecisions(res.data);
          }
        })
        .catch(error => {
          swalToastError('Get Fault Decisions Failed!');
        });
    }
  }, [showDecision]);

  const onAddQC = e => {
    e.stopPropagation();
    if (!productType || productType == '') {
      swalToastError('No barcode had been scanned');
      return;
    }

    getFaultListByProductType(productType).then(res => {
      if (res?.success) {
        let faultSource = res?.faultList;
        setFaultList(faultSource);

        assignFaultProduct(faultSource);
        assignFaultGroup('', faultSource);
        assignFaultDescs('', '', faultSource);
        assignFaultDetails('', '', '', faultSource);
        assignFaultComments('', '', '', '', faultSource);
      }
    });

    setIsOpenQCModal(true);
  };

  const resetQC = () => {
    setShowGroups(false);
    setShowDescs(false);
    setShowDescs(false);
    setShowDetails(false);
    setShowComments(false);
    setShowDecision(false);

    setSelectedProduct('');
    setSelectedGroup('');
    setSelectedDesc('');
    setSelectedDetail('');
    setSelectedComment('');
    setSelectedDecision('');
  };

  const handleClose = () => {
    setIsOpenQCModal(false);

    resetQC();
  };

  const onSaveQualityCheck = () => {
    let logItem = {
      headerId: headerId,
      frameId: frameId,
      faultId: 0,
      faultItem: {
        id: 0,
        productType: productType,
        product: selectedProduct,
        faultGroup: selectedGroup,
        faultDesc: selectedDesc,
        detail: selectedDetail,
        comment: selectedComment,
        areaOccured: selectedArea,
        alertType: ''
      },
      decision: selectedDecision,
      createdBy: userId,
      userName: userName,
      stationId: 0,
      stageId: 0,
      userMadeErrorId: 0,
      timeErrorWasMade: null
    };

    if (selectedProduct === '' || selectedProduct == undefined) {
      swalToastError('Please select a Product');
      return;
    }
    if (selectedGroup === '' || selectedGroup == undefined) {
      swalToastError('Please select a Fault Group');
      return;
    }
    if (selectedDesc === '' || selectedDesc == undefined) {
      swalToastError('Please select a Fault Description');
      return;
    }
    if (selectedDetail === '' || selectedDetail == undefined) {
      swalToastError('Please select a Detail');
      return;
    }
    if (selectedComment === '' || selectedComment == undefined) {
      swalToastError('Please select a Comment');
      return;
    }
    if (selectedDecision === '' || selectedDecision == undefined) {
      swalToastError('Please select a Decision');
      return;
    }

    addRejectLog(logItem)
      .then(res => {
        if (res?.success) {
          swalToastSuccess('Quality Check has been recorded');
          setIsOpenQCModal(false);
          resetQC();
        }
      })
      .catch(err => {
        console.log(err);
        swalToastError('Error. Something went wrong!');
      });
  };

  const handleSelectProduct = product => {
    assignFaultGroup(product, faultList);
    assignFaultDescs(product, '', faultList);
    assignFaultDetails(product, '', '', faultList);
    assignFaultComments(product, '', '', '', faultList);

    setSelectedProduct(product);
    setSelectedGroup('');
    setSelectedDesc('');
    setSelectedDetail('');
    setSelectedComment('');
    setSelectedDecision('');

    setShowGroups(true);
    setShowDescs(false);
    setShowDetails(false);
    setShowComments(false);
    setShowDecision(false);
  };

  const handleSelectGroup = group => {
    assignFaultDescs(selectedProduct, group, faultList);
    assignFaultDetails(selectedProduct, group, '', faultList);
    assignFaultComments(selectedProduct, group, '', '', faultList);

    var groupOptionCollection =
      document.getElementsByClassName('faultGroupItem');

    for (let i = 0; i < groupOptionCollection.length; i++) {
      if (groupOptionCollection[i].innerHTML != group) {
        groupOptionCollection[i].classList.remove('active');
      } else {
        groupOptionCollection[i].classList.add('active');
        //console.log(groupOptionCollection[i].innerHTML);
      }
    }

    setSelectedGroup(group);
    setSelectedDesc('');
    setSelectedDetail('');
    setSelectedComment('');
    setSelectedDecision('');

    setShowDescs(true);
    setShowDetails(false);
    setShowComments(false);
    setShowDecision(false);
  };

  const handleSelectDesc = desc => {
    assignFaultDetails(selectedProduct, selectedGroup, desc, faultList);
    assignFaultComments(selectedProduct, selectedGroup, desc, '', faultList);

    setSelectedDesc(desc);
    setSelectedDetail('');
    setSelectedComment('');
    setSelectedDecision('');

    setShowDetails(true);
    setShowComments(false);
    setShowDecision(false);
  };

  const handleSelectDetail = detail => {
    assignFaultComments(
      selectedProduct,
      selectedGroup,
      selectedDesc,
      detail,
      faultList
    );

    setSelectedDetail(detail);
    setSelectedDecision('');

    setShowComments(true);
    setShowDecision(true);
  };

  const handleSelectComment = comment => {
    setSelectedComment(comment);
    setSelectedDecision('');

    setShowDecision(true);
  };

  const handleSelectDecision = decision => {
    setSelectedDecision(decision);

    var area = faultList
      .filter(
        f =>
          f.productType == productType &&
          f.product == selectedProduct &&
          f.faultGroup == selectedGroup &&
          f.faultDesc == selectedDesc &&
          f.detail == selectedDetail &&
          f.comment == selectedComment
      )
      .map(f => f.areaOccured)
      .filter((a, index, arr) => arr.indexOf(a) === index)[0];

    setSelectedArea(area);
  };

  const assignFaultProduct = masterSource => {
    setProducts(
      masterSource
        .map(fault => fault.product)
        .filter((product, index, arr) => arr.indexOf(product) === index)
    );
  };

  const assignFaultGroup = (product, masterSource) => {
    let faultGroups = [];

    if (product != '') {
      faultGroups = masterSource
        .filter(fault => fault.product == product)
        .map(fault => fault.faultGroup)
        .filter((group, index, arr) => arr.indexOf(group) === index);
    } else {
      faultGroups = masterSource
        .map(fault => fault.faultGroup)
        .filter((group, index, arr) => arr.indexOf(group) === index);
    }

    setFaultGroups(faultGroups);
  };

  const assignFaultDescs = (product, group, masterSource) => {
    let faultDescs = [];

    if (product != '' && group != '') {
      faultDescs = masterSource
        .filter(fault => fault.product == product && fault.faultGroup == group)
        .map(fault => fault.faultDesc)
        .filter((desc, index, arr) => arr.indexOf(desc) === index);
    } else if (product != '' && group == '') {
      faultDescs = masterSource
        .filter(fault => fault.product == product)
        .map(fault => fault.faultDesc)
        .filter((desc, index, arr) => arr.indexOf(desc) === index);
    } else {
      faultDescs = masterSource
        .map(fault => fault.faultDesc)
        .filter((desc, index, arr) => arr.indexOf(desc) === index);
    }

    setFaultDescs(faultDescs);
  };

  const assignFaultDetails = (product, group, desc, masterSource) => {
    let faultDetails = [];

    if (product != '' && group != '' && desc != '') {
      faultDetails = masterSource
        .filter(
          fault =>
            fault.product == product &&
            fault.faultGroup == group &&
            fault.faultDesc == desc
        )
        .map(fault => fault.detail)
        .filter((detail, index, arr) => arr.indexOf(detail) === index);
    } else if (product != '' && group != '' && desc == '') {
      faultDetails = masterSource
        .filter(fault => fault.product == product && fault.faultGroup == group)
        .map(fault => fault.detail)
        .filter((detail, index, arr) => arr.indexOf(detail) === index);
    } else if (product != '' && group == '' && desc == '') {
      faultDetails = masterSource
        .filter(fault => fault.product == product)
        .map(fault => fault.detail)
        .filter((detail, index, arr) => arr.indexOf(detail) === index);
    } else {
      faultDetails = masterSource
        .map(fault => fault.detail)
        .filter((detail, index, arr) => arr.indexOf(detail) === index);
    }
    setDetails(faultDetails);
  };

  const assignFaultComments = (product, group, desc, detail, masterSource) => {
    let faultComments = [];

    if (product != '' && group != '' && desc != '' && detail != '') {
      faultComments = masterSource
        .filter(
          fault =>
            fault.product == product &&
            fault.faultGroup == group &&
            fault.faultDesc == desc &&
            fault.detail == detail
        )
        .map(fault => fault.comment)
        .filter((comment, index, arr) => arr.indexOf(comment) === index);

      setSelectedComment(faultComments[0]);
    } else if (product != '' && group != '' && desc != '' && detail == '') {
      faultComments = masterSource
        .filter(
          fault =>
            fault.product == product &&
            fault.faultGroup == group &&
            fault.faultDesc == desc
        )
        .map(fault => fault.comment)
        .filter((comment, index, arr) => arr.indexOf(comment) === index);
    } else if (product != '' && group != '' && desc == '' && detail == '') {
      faultComments = masterSource
        .filter(fault => fault.product == product && fault.faultGroup == group)
        .map(fault => fault.comment)
        .filter((comment, index, arr) => arr.indexOf(comment) === index);
    } else if (product != '' && group == '' && desc == '' && detail == '') {
      faultComments = masterSource
        .filter(fault => fault.product == product)
        .map(fault => fault.comment)
        .filter((comment, index, arr) => arr.indexOf(comment) === index);
    } else {
      faultComments = masterSource
        .map(fault => fault.comment)
        .filter((comment, index, arr) => arr.indexOf(comment) === index);
    }
    setComments(faultComments);
  };

  if (products?.length > 0) {
    products.forEach(function (item, index) {
      faultProductOptions.push(
        <ListGroup.Item
          key={'product' + (index + 1)}
          action
          href={`#${item}`}
          onClick={() => handleSelectProduct(item)}
        >
          {item}
        </ListGroup.Item>
      );
    });
  } else {
    faultProductOptions.push(
      <ListGroup.Item key={'group'} action href={`#`}>
        No product found.
      </ListGroup.Item>
    );
  }

  if (faultGroups?.length > 0) {
    faultGroups.forEach(function (item, index) {
      faultGroupOptions.push(
        <ListGroup.Item
          className="faultGroupItem"
          key={'group' + (index + 1)}
          action
          href={`#${item}`}
          onClick={() => handleSelectGroup(item)}
        >
          {item}
        </ListGroup.Item>
      );
    });
  } else {
    faultGroupOptions.push(
      <ListGroup.Item key={'group'} action href={`#`}>
        No group found.
      </ListGroup.Item>
    );
  }

  if (faultDescs?.length > 0) {
    faultDescs.forEach(function (item, index) {
      faultDescOptions.push(
        <ListGroup.Item
          key={'desc' + (index + 1)}
          action
          href={`#${item}`}
          onClick={() => handleSelectDesc(item)}
        >
          {item}
        </ListGroup.Item>
      );
    });
  } else {
    faultDescOptions.push(
      <ListGroup.Item key={'group'} action href={`#`}>
        No desc found.
      </ListGroup.Item>
    );
  }

  if (details?.length > 0) {
    details.forEach(function (item, index) {
      faultDetailOptions.push(
        <ListGroup.Item
          key={'detail' + (index + 1)}
          action
          href={`#${item}`}
          onClick={() => handleSelectDetail(item)}
        >
          {item}
        </ListGroup.Item>
      );
    });
  } else {
    faultDetailOptions.push(
      <ListGroup.Item key={'group'} action href={`#`}>
        No detail found.
      </ListGroup.Item>
    );
  }

  if (comments?.length > 0) {
    comments.forEach(function (item, index) {
      faultCommentOptions.push(
        <ListGroup.Item
          key={'cmt' + (index + 1)}
          action
          href={`#${item}`}
          onClick={() => handleSelectComment(item)}
          active={true}
        >
          {item}
        </ListGroup.Item>
      );
    });
  } else {
    faultCommentOptions.push(
      <ListGroup.Item key={'group'} action href={`#`}>
        No detail found.
      </ListGroup.Item>
    );
  }

  let resultClass1 = '';
  if (selectedProduct != '') resultClass1 = 'passed';
  let resultClass2 = '';
  if (selectedGroup != '') resultClass2 = 'passed';
  let resultClass3 = '';
  if (selectedDesc != '') resultClass3 = 'passed';
  let resultClass4 = '';
  if (selectedDetail != '') resultClass4 = 'passed';
  let resultClass5 = '';
  if (selectedComment != '') resultClass5 = 'passed';
  let resultClass6 = '';
  if (selectedDecision != '') resultClass6 = 'passed';

  const handleCloseAddingNewFault = () => {
    setNewFault();
    setIsAddingNewFault(false);
  };

  const handleChangeInput = e => {
    setNewFault({
      ...newFault,
      [e.target.name]: e.target.value.toUpperCase()
    });
  };

  const handleChangeDecision = e => {
    setDecision(e.target.value.toUpperCase());
  };

  const onSaveDecision = () => {
    if (!decision) {
      swalToastError(`Decision Cann't be Blank`);
      return;
    }

    addDecision(decision)
      .then(res => {
        if (res.data) {
          swalToastSuccess('Add Decsion Success');

          setDecisions([...decisions, decision]);
          setDecision('');
        }
      })
      .catch(error => {
        swalToastError('Add Decision Failed!');
      });
  };

  const onSaveFaultList = () => {
    if (!newFault.productType) {
      swalToastError(`Product Type Cann't be Blank`);
      return;
    } else if (!newFault.product) {
      swalToastError(`Product Cann't be Blank`);
      return;
    } else if (!newFault.faultGroup) {
      swalToastError(`Fault Group Cann't be Blank`);
      return;
    } else if (!newFault.faultDesc) {
      swalToastError(`Fault Description Cann't be Blank`);
      return;
    } else if (!newFault.detail) {
      swalToastError(`Fault Detail Cann't be Blank`);
      return;
    } else if (!newFault.comment) {
      swalToastError(`Fault Comment Cann't be Blank`);
      return;
    }
    addFaultList(newFault)
      .then(res => {
        if (res.data) {
          swalToastSuccess('Add Fault List Success');
          getFrameProductType(barcode)
            .then(res => {
              if (res?.success) {
                setProductType(res.productType);
                setHeaderId(res.headerId);
                setFrameId(res.frameId);
              }
            })
            .catch(error => {
              swalToastError('Get Frame Product Type Failed!');
            });
        } else {
          swalToastError('New Fault Has Been Duplicated!');
        }
      })
      .catch(error => {
        console.log(error);
        swalToastError('Add Fault List Failed!');
      })
      .finally(() => {
        resetQC();
        setNewFault(defaultFault);
        setIsAddingNewFault(false);
        getFaultListByProductType(productType).then(res => {
          if (res?.success) {
            let faultSource = res?.faultList;
            setFaultList(faultSource);

            assignFaultProduct(faultSource);
            assignFaultGroup('', faultSource);
            assignFaultDescs('', '', faultSource);
            assignFaultDetails('', '', '', faultSource);
            assignFaultComments('', '', '', '', faultSource);
          }
        });
      });
  };

  return (
    <>
      <Button
        variant="danger"
        onClick={e => onAddQC(e)}
        onTouchStart={e => onAddQC(e)}
      >
        Add QC
      </Button>
      <Modal
        show={isOpenQCModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="qcModal"
        contentClassName="border"
      >
        <Modal.Header
          closeButton
          className="bg-light px-card border-bottom-0 gap-5"
        >
          <Modal.Title as="h4">
            Quality Check for Product Type: {productType}
          </Modal.Title>
          {permissionId == process.env.REACT_APP_ADMIN_PERMISSION && (
            <IconButton
              className="text-end"
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={() => {
                setIsAddingNewFault(true);
              }}
            >
              <span className="d-none d-sm-inline-block ms-1">New Fault</span>
            </IconButton>
          )}
        </Modal.Header>
        <Modal.Body style={{ minHeight: '70vh' }}>
          <div>
            <Row>
              <Col className="qc-column" style={{ maxWidth: '20%' }}>
                <div
                  className="text-center"
                  style={
                    !isTablet && !isMobile
                      ? {
                          fontSize: '1.2rem',
                          fontWeight: 'bold',
                          marginBottom: '0.5rem'
                        }
                      : { fontWeight: 'bold', marginBottom: '0.5rem' }
                  }
                >
                  Product
                  <Badge
                    variant="primary"
                    pill
                    style={
                      !isTablet && !isMobile
                        ? { fontSize: '12px' }
                        : { fontSize: '10px' }
                    }
                  >
                    1
                  </Badge>
                </div>
                <ListGroup className="qcListGroup">
                  {faultProductOptions}
                </ListGroup>
              </Col>

              {showGroups && (
                <Col className="qc-hide-right-column">
                  <div
                    className="text-center"
                    style={
                      !isTablet && !isMobile
                        ? {
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            marginBottom: '0.5rem'
                          }
                        : { fontWeight: 'bold', marginBottom: '0.5rem' }
                    }
                  >
                    Fault Group
                    <Badge
                      variant="primary"
                      pill
                      style={
                        !isTablet && !isMobile
                          ? { fontSize: '12px' }
                          : { fontSize: '10px' }
                      }
                    >
                      2
                    </Badge>
                  </div>
                  <ListGroup className="qcListGroup faultGroup">
                    {faultGroupOptions.slice(
                      0,
                      Math.round(faultGroupOptions.length / 2)
                    )}
                  </ListGroup>
                </Col>
              )}
              {showGroups && faultGroupOptions.length > 9 && (
                <Col className="qc-hide-left-column">
                  <ListGroup
                    className="qcListGroup faultGroup"
                    style={{ top: '27px', position: 'relative' }}
                  >
                    {faultGroupOptions.slice(
                      Math.round(faultGroupOptions.length / 2)
                    )}
                  </ListGroup>
                </Col>
              )}

              {showDescs ? (
                <Col className="qc-column">
                  <div
                    className="text-center"
                    style={
                      !isTablet && !isMobile
                        ? {
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            marginBottom: '0.5rem'
                          }
                        : { fontWeight: 'bold', marginBottom: '0.5rem' }
                    }
                  >
                    Fault Description
                    <Badge
                      variant="primary"
                      pill
                      style={
                        !isTablet && !isMobile
                          ? { fontSize: '12px' }
                          : { fontSize: '10px' }
                      }
                    >
                      3
                    </Badge>
                  </div>
                  <ListGroup className="qcListGroup">
                    {faultDescOptions}
                  </ListGroup>
                </Col>
              ) : (
                <Col sm={2}></Col>
              )}
              {showDetails ? (
                <Col className="qc-column">
                  <div
                    className="text-center"
                    style={
                      !isTablet && !isMobile
                        ? {
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            marginBottom: '0.5rem'
                          }
                        : { fontWeight: 'bold', marginBottom: '0.5rem' }
                    }
                  >
                    Detail
                    <Badge
                      variant="primary"
                      pill
                      style={
                        !isTablet && !isMobile
                          ? { fontSize: '12px' }
                          : { fontSize: '10px' }
                      }
                    >
                      4
                    </Badge>
                  </div>
                  <ListGroup className="qcListGroup">
                    {faultDetailOptions}
                  </ListGroup>
                </Col>
              ) : (
                <Col sm={2}></Col>
              )}
              {showDecision ? (
                <Col className="qc-column">
                  <div
                    className="text-center"
                    style={
                      !isTablet && !isMobile
                        ? {
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            marginBottom: '0.5rem'
                          }
                        : { fontWeight: 'bold', marginBottom: '0.5rem' }
                    }
                  >
                    Decision
                    <Badge
                      variant="primary"
                      pill
                      style={
                        !isTablet && !isMobile
                          ? { fontSize: '12px' }
                          : { fontSize: '10px' }
                      }
                    >
                      6
                    </Badge>
                  </div>
                  <ListGroup className="qcListGroup">
                    {decisions &&
                      decisions.map((item, index) => (
                        <ListGroup.Item
                          key={item}
                          action
                          href={`#${item}`}
                          onClick={() => handleSelectDecision(item)}
                        >
                          {item}
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                  {permissionId == process.env.REACT_APP_ADMIN_PERMISSION && (
                    <Col
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '3fr 1fr'
                      }}
                    >
                      <Form.Control
                        name="decision"
                        type="text"
                        value={decision}
                        onChange={handleChangeDecision}
                      ></Form.Control>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        onClick={onSaveDecision}
                      >
                        Save
                      </Button>
                    </Col>
                  )}
                </Col>
              ) : (
                <Col sm={2}></Col>
              )}
            </Row>

            <Row
              className={showComments ? 'qc-column' : ''}
              style={{
                marginTop: '12px',
                marginRight: '20px',
                marginLeft: '20px'
              }}
            >
              <Col sm={4}></Col>
              {showComments ? (
                <Col sm={5} style={{ paddingBottom: '10px' }}>
                  <div
                    className="text-center"
                    style={
                      !isTablet && !isMobile
                        ? {
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            marginBottom: '0.5rem'
                          }
                        : { fontWeight: 'bold', marginBottom: '0.5rem' }
                    }
                  >
                    Comment
                    <Badge
                      variant="primary"
                      pill
                      style={
                        !isTablet && !isMobile
                          ? { fontSize: '12px' }
                          : { fontSize: '10px' }
                      }
                    >
                      5
                    </Badge>
                  </div>
                  <ListGroup className="qcListGroup">
                    {faultCommentOptions}
                  </ListGroup>
                </Col>
              ) : (
                ''
              )}
            </Row>

            <hr></hr>

            <Row>
              <h5>Preview QC Result:</h5>
            </Row>

            <Row
              style={
                !isTablet && !isMobile
                  ? { fontSize: '17px' }
                  : { fontSize: '12px' }
              }
            >
              <Col className={resultClass1} title="Product">
                {selectedProduct != '' ? selectedProduct : 'UNKNOWN'}
                <FontAwesomeIcon icon={faCaretRight} className="qcCaretIcon" />
              </Col>
              <Col className={resultClass2} title="Fault Group">
                {selectedGroup != '' ? selectedGroup : 'UNKNOWN'}
                <FontAwesomeIcon icon={faCaretRight} className="qcCaretIcon" />
              </Col>
              <Col className={resultClass3} title="Fault Description">
                {selectedDesc != '' ? selectedDesc : 'UNKNOWN'}
                <FontAwesomeIcon icon={faCaretRight} className="qcCaretIcon" />
              </Col>
              <Col className={resultClass4} title="Detail">
                {selectedDetail != '' ? selectedDetail : 'UNKNOWN'}
                <FontAwesomeIcon icon={faCaretRight} className="qcCaretIcon" />
              </Col>
              <Col className={resultClass5} title="Comment">
                {selectedComment != '' ? selectedComment : 'UNKNOWN'}
                <FontAwesomeIcon icon={faCaretRight} className="qcCaretIcon" />
              </Col>
              <Col className={resultClass6} title="Decision">
                {selectedDecision != '' ? selectedDecision : 'UNKNOWN'}
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={onSaveQualityCheck}
          >
            Save
          </Button>
          <Button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={isAddingNewFault}
        onHide={handleCloseAddingNewFault}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h4">New Fault</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Col>
              <Form.Label>Product Type</Form.Label>
              <Form.Control
                as={'select'}
                name="productType"
                onChange={handleChangeInput}
              >
                <option>Select Product Type</option>
                {productTypes.length > 0 &&
                  productTypes.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </Form.Control>
            </Col>
            <Col>
              <Form.Label>Product</Form.Label>
              <Form.Control
                as={'select'}
                name="product"
                onChange={handleChangeInput}
              >
                <option>Select Product</option>
                {productsAdding.length > 0 &&
                  productsAdding.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </Form.Control>
            </Col>
            <Col>
              <Form.Label>Fault Group</Form.Label>

              <InputGroup>
                {isTypingFaultGroup ? (
                  <Form.Control
                    name="faultGroup"
                    type="text"
                    value={newFault?.faultGroup}
                    onChange={handleChangeInput}
                  ></Form.Control>
                ) : (
                  <Form.Control
                    as={'select'}
                    name="faultGroup"
                    onChange={handleChangeInput}
                  >
                    <option>Select Product</option>
                    {faultGroupsDropDown.length > 0 &&
                      faultGroupsDropDown.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                  </Form.Control>
                )}
                <Button
                  variant="info"
                  onClick={e => {
                    setIsTypingFaultGroup(!isTypingFaultGroup);
                    setNewFault({ ...newFault, faultGroup: '' });
                  }}
                >
                  +
                </Button>
              </InputGroup>
            </Col>
            <Col>
              <Form.Label>Fault Description</Form.Label>

              <InputGroup>
                {isTypingFaultDesc ? (
                  <Form.Control
                    name="faultDesc"
                    type="text"
                    value={newFault?.faultDesc}
                    onChange={handleChangeInput}
                  ></Form.Control>
                ) : (
                  <Form.Control
                    as={'select'}
                    name="faultDesc"
                    onChange={handleChangeInput}
                  >
                    <option>Select Product</option>
                    {faultDescsDropDown.length > 0 &&
                      faultDescsDropDown.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                  </Form.Control>
                )}
                <Button
                  variant="info"
                  onClick={() => {
                    setIsTypingFaultDesc(!isTypingFaultDesc);
                    setNewFault({ ...newFault, faultDesc: '' });
                  }}
                >
                  +
                </Button>
              </InputGroup>
            </Col>
            <Col>
              <Form.Label>Fault Detail</Form.Label>
              <Form.Control
                name="detail"
                type="text"
                value={newFault?.detail}
                onChange={handleChangeInput}
              ></Form.Control>
            </Col>
            <Col>
              <Form.Label>Fault Comment</Form.Label>
              <Form.Control
                name="comment"
                type="text"
                value={newFault?.comment}
                onChange={handleChangeInput}
              ></Form.Control>
            </Col>
            <Col>
              <Form.Label>Area Occured</Form.Label>
              <Form.Control
                as={'select'}
                name="areaOccured"
                onChange={handleChangeInput}
              >
                <option>Select Area</option>
                {areas.length > 0 &&
                  areas.map((item, index) => (
                    <option key={index} value={item.areaName}>
                      {item.areaName}
                    </option>
                  ))}
              </Form.Control>
            </Col>
            <Col>
              <Form.Label>Alert Type</Form.Label>
              <Form.Control
                as={'select'}
                name="alertType"
                onChange={handleChangeInput}
              >
                <option>Select Alert Type</option>
                <option value={'RED'}>RED</option>
                <option value={'YELLOW'}>YELLOW</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={onSaveFaultList}
          >
            Save
          </Button>
          <Button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseAddingNewFault}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
