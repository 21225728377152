import StationSpinner from 'components/station/common/Spinner';
import { React, useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { getSashInfo } from 'services/sashLocatorService';

export default function SashLocatorWidget({
  barcode,
  isEnableClearSash,
  setIsEnableClearSash
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [sashLocations, setSashLocation] = useState([]);

  const handleClearSash = e => {
    setIsEnableClearSash(e.target.checked);
    localStorage.setItem('enable-clear-sash', e.target.checked);
  };

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);
      getSashInfo(barcode).then(res => {
        if (res.data) {
          setSashLocation(res.data);
        }
        setIsLoading(false);
      });
    }
  }, [barcode]);

  useEffect(() => {
    if (!isEnableClearSash) {
      localStorage.setItem('enable-clear-sash', true);
    }
  }, []);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <div>
        <Form.Check
          type="switch"
          id="isEnableClearSash"
          label="Enable To Clear Sash Location"
          onChange={handleClearSash}
          defaultChecked={
            JSON.parse(localStorage.getItem('enable-clear-sash')) ||
            isEnableClearSash
          }
        />
      </div>
      <div>
        <Table>
          <thead>
            <tr>
              <th>Rack Number</th>
              <th>Slot Number</th>
              <th>Sash No</th>
            </tr>
          </thead>
          <tbody>
            {sashLocations.length > 0 ? (
              sashLocations.map((item, index) => (
                <tr key={index}>
                  <td>{item.rackNumber}</td>
                  <td>{item.slotNumber}</td>
                  <td>{item.sashNo}</td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan={3}>No Record Found.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}
