import { React, useState } from 'react';
import { Form } from 'react-bootstrap';
import { isMobile, isTablet } from 'react-device-detect';

export default function BarcodeInput(props) {
  const [barcode, setBarcode] = useState('');

  const tryFocus = () => {
    if (
      !document.body.classList.contains('modal-open') &&
      !document.body.classList.contains('swal2-shown') &&
      !document.getElementById('product-checking-form')
    ) {
      !isMobile && !isTablet && document.getElementById('barcodeInput').focus();
    }
  };

  const handleChange = e => {
    setBarcode(e.target.value);
  };

  const handleKeyUp = e => {
    e.preventDefault();

    if (e.key === 'Enter') {
      scanBarcode(barcode);
      setBarcode('');
    }
  };

  const handleBlur = e => {
    e.preventDefault();
    e.stopPropagation();
    tryFocus();
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  const scanBarcode = barcodeValue => {
    console.log(barcodeValue);
    props.onScan(barcodeValue);
  };

  return (
    <div style={{ margin: '5px' }}>
      <Form onSubmit={e => handleSubmit(e)} autoComplete="off">
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Scan barcode"
            size="sm"
            className=""
            name="barcode"
            id="barcodeInput"
            value={barcode}
            onChange={e => handleChange(e)}
            onKeyUp={e => handleKeyUp(e)}
            // onFocus={this.handleFocus}
            onBlur={handleBlur}
            autoComplete="off"
            readOnly={props.readOnly}
            //disabled={this.state.isLoading}
          />
        </Form.Group>
      </Form>
    </div>
  );
}
