import AccessDenied from 'components/errors/AccessDenied';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { getPermission } from 'redux/slices/permissionSlice';
import {
  getListFaultArea,
  getStationScanningActions,
  getStationStages
} from 'redux/slices/stationSlice';
import { addStation } from 'services/stationService';

export default function NewStation() {
  const dispatch = useDispatch();
  let stageOptions = [];
  let actionOptions = [];
  let faultAreaOptions = [];

  const [newStation, setNewStation] = useState({
    stationName: '',
    scanningActionId: 0,
    startStage: 0,
    completedStage: 0,
    scanNextToComplete: false,
    faultAreaId: 0,
    disabledSessionExpired: false,
    minutesSessionExpired: 15,
    enableUidLogin: false
  });
  const [validated, setValidated] = useState(false);
  const permissionId = useSelector(store => store.permission.permissionId);

  useEffect(() => {
    dispatch(getStationStages());
    dispatch(getStationScanningActions());
    dispatch(getListFaultArea());
    if (permissionId <= 0) {
      let loggedData = JSON.parse(localStorage.getItem('login-data'));
      if (loggedData && loggedData.isLogged)
        dispatch(getPermission(loggedData.permissionId));
    }
  }, []);

  const handleInputChange = e => {
    setNewStation({ ...newStation, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = e => {
    setNewStation({ ...newStation, [e.target.name]: e.target.checked });
  };

  const handleSubmit = e => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (newStation.scanningActionId == 0) {
      swalToastError('Please select an action');
      return;
    }
    if (newStation.faultAreaId == 0) {
      swalToastError('Please select an area');
      return;
    }
    if (form.checkValidity() === true) {
      addStation(newStation).then(res => {
        if (res?.success) {
          swalToastSuccess('New Station has been added');
          clearInputValues();
        } else {
          swalToastError('Error. Something went wrong!');
        }
      });
    } else {
      setValidated(true);
    }
  };

  const clearInputValues = e => {
    setNewStation({
      stationName: '',
      scanningActionId: 0,
      startStage: 0,
      completedStage: 0,
      scanNextToComplete: false,
      faultAreaId: 0
    });
  };

  const stages = useSelector(store => store.station.stages);
  const scanningActions = useSelector(store => store.station.actions);
  const faultAreas = useSelector(store => store.station.faultAreas);

  if (stages?.length > 0) {
    stages.forEach(function (item, index) {
      stageOptions.push(
        <option key={'stage' + index + 1} value={item.id}>
          {item.name}
        </option>
      );
    });
  } else {
    stageOptions.push(
      <option key={'stage0'} value={0}>
        No stage found.
      </option>
    );
  }

  if (scanningActions?.length > 0) {
    actionOptions.push(
      <option key={'action0'} value={0}>
        Please select an action
      </option>
    );
    scanningActions.forEach(function (item, index) {
      actionOptions.push(
        <option key={'action' + index + 1} value={item.id}>
          {item.actionName}
        </option>
      );
    });
  } else {
    actionOptions.push(
      <option key={'action0'} value={0}>
        No action found.
      </option>
    );
  }

  if (faultAreas?.length > 0) {
    faultAreaOptions.push(
      <option key={'area0'} value={0}>
        Please select an area
      </option>
    );
    faultAreas.forEach(function (item, index) {
      faultAreaOptions.push(
        <option key={'area' + index + 1} value={item.id}>
          {item.areaName}
        </option>
      );
    });
  } else {
    faultAreaOptions.push(
      <option key={'area0'} value={0}>
        No area found.
      </option>
    );
  }

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <div>
      <div className="g-3 mt-2 row">
        <div className="col-lg-10">
          <Card>
            <Card.Header>
              <h5 className="mb-0">New Station</h5>
            </Card.Header>
            <Card.Body>
              <Form
                autoComplete="off"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Form.Group as={Row} className="mb-3">
                  <Form.Label>Station Name:</Form.Label>
                  <Col className="col-lg-6">
                    <Form.Control
                      name="stationName"
                      type="text"
                      autoFocus={true}
                      required
                      value={newStation.stationName}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please type in Station Name
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col className="col-lg-6">
                    <Form.Label>Start Stage:</Form.Label>
                    <Form.Control
                      as="select"
                      name="startStage"
                      drop="down"
                      value={newStation.startStage}
                      onChange={handleInputChange}
                    >
                      {stageOptions}
                    </Form.Control>
                  </Col>

                  <Col className="col-lg-6">
                    <Form.Label>Completed Stage:</Form.Label>
                    <Form.Control
                      as="select"
                      name="completedStage"
                      value={newStation.completedStage}
                      onChange={handleInputChange}
                    >
                      {stageOptions}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col className="col-lg-6">
                    <Form.Label>Complete Action:</Form.Label>
                    <Form.Control
                      as="select"
                      name="scanningActionId"
                      value={newStation.scanningActionId}
                      onChange={handleInputChange}
                      required
                    >
                      {actionOptions}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col className="col-lg-6">
                    <Form.Label>Fault Area:</Form.Label>
                    <Form.Control
                      as="select"
                      name="faultAreaId"
                      value={newStation.faultAreaId}
                      onChange={handleInputChange}
                      required
                    >
                      {faultAreaOptions}
                    </Form.Control>
                  </Col>
                  <Col className="col-lg-6">
                    <Form.Label>Timing Session Expired (Minutes):</Form.Label>
                    <Form.Control
                      type="number"
                      name="minutesSessionExpired"
                      value={newStation.minutesSessionExpired}
                      onChange={handleInputChange}
                      required
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col className="col-lg-6">
                    <Form.Check
                      inline
                      label="Scan Next Item to Complete"
                      name="scanNextToComplete"
                      type="checkbox"
                      id={`checkboxScanNext`}
                      value={newStation.scanNextToComplete}
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                  <Col className="col-lg-6">
                    <Form.Check
                      inline
                      label="Disabled Session Expired"
                      name="disabledSessionExpired"
                      type="checkbox"
                      id={`disabledSessionExpired`}
                      value={newStation.disabledSessionExpired}
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col className="col-lg-6">
                    <Form.Check
                      inline
                      label="Enable UID Login"
                      name="enableUidLogin"
                      type="checkbox"
                      id={`enableUidLogin`}
                      value={newStation.enableUidLogin}
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col className="d-flex justify-content-end gap-2">
                    <Button size="sm" variant="primary" type="submit">
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={clearInputValues}
                    >
                      Clear
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <AccessDenied></AccessDenied>
  );
}
