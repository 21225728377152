import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';
import { CATEGORIES } from 'helpers/utils';
import { React, useEffect, useState } from 'react';
import { Badge, Col, Row, Table } from 'react-bootstrap';
import { startStillage } from 'services/qcService';

var dayjs = require('dayjs');

export default function StillageDetail({
  barcode,
  startStage,
  userId,
  onScanStarted,
  onScanCompleted,
  setProductComplted
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [stillages, setStillages] = useState([]);

  useEffect(() => {
    if (barcode) {
      onScanStarted();
      setIsLoading(true);
      const payload = { stillageNo: barcode, stage: startStage, userId };
      startStillage(payload)
        .then(res => {
          if (
            res &&
            res.data &&
            res.data.stillages &&
            res.data.stillages.length > 0
          ) {
            const stillages = res.data.stillages.filter(item => item.jobNo);
            setStillages(stillages);
          }
          onScanCompleted(res.data.userTrackingId);
          setProductComplted(CATEGORIES.STILLAGE);
          setIsLoading(false);
        })
        .catch(error => {
          swalToastError('Get Stillage Detail Failed!');
        });
    }
  }, [barcode]);
  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div>
      <Table style={{ cursor: 'default' }}>
        <thead>
          <tr>
            <th scope="col">Customer</th>
            <th scope="col">Delivery Date</th>
          </tr>
        </thead>
        <tbody>
          {stillages.length > 0 &&
            stillages.map((item, index) => (
              <tr key={index}>
                <td>
                  {item.customer}
                  <Badge style={{ marginLeft: '5px' }} bg="danger">
                    {item.priorityType}
                  </Badge>
                </td>
                <td className="widget-text">
                  {item.requiredDate
                    ? dayjs(item.requiredDate).format('DD/MM/YYYY')
                    : ''}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}
